import styled from '@emotion/styled';
import React from 'react';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface FilterHeaderType {
  label: string;
  value: string;
}

interface FilterTypeHeaderProps<T> {
  filterTypeList: T[];
  handleCheckExist?: (i: string) => boolean;
  handleSelect: (i: T) => void;
  selectedFilter: T;
}

const FilterTypeHeader = <T extends FilterHeaderType>({
  filterTypeList,
  handleCheckExist,
  handleSelect,
  selectedFilter,
}: FilterTypeHeaderProps<T>) => {
  return (
    <Container>
      {filterTypeList.map((item: T, index: number) => (
        <Wrapper key={`filterItem_${index}`}>
          <FilterTypeItem selected={selectedFilter.value === item.value} onClick={() => handleSelect(item)}>
            {item.label}
          </FilterTypeItem>
          {handleCheckExist && handleCheckExist(item.value) && <FilterExistFlag />}
        </Wrapper>
      ))}
    </Container>
  );
};

export default FilterTypeHeader;

const Container = styled.div`
  display: flex;
  padding: 0 ${toRem(16)};
`;

const Wrapper = styled.div`
  margin-right: ${toRem(22)};
  display: flex;
  position: relative;
`;

const FilterTypeItem = styled.span<{ selected: boolean }>`
  font-size: ${toRem(18)};
  font-weight: 600;
  color: ${(props) => (props.selected ? GLOBAL_COLOR.GRAY_900 : GLOBAL_COLOR.GRAY_300)};
  margin-right: ${toRem(4)};
`;

const FilterExistFlag = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: ${GLOBAL_COLOR.BLUE_500};
  position: absolute;
  top: 6px;
  right: -6px;
`;
