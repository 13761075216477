import styled from '@emotion/styled';
import React from 'react';
import CheckBox from '@/components/common/CheckBox';
import { toRem } from '@/utils/commonUtils';

export interface CheckboxType {
  label: string;
  value: string | number | undefined;
}

interface FilterCheckboxContentProps<T> {
  itemList: T[] | undefined;
  onCheckboxClick: (i: T) => void;
  isChecked: (i: T) => boolean;
}

const FilterCheckboxContent = <T extends CheckboxType>({
  itemList,
  onCheckboxClick,
  isChecked,
}: FilterCheckboxContentProps<T>) => {
  return (
    <Container>
      {itemList?.map((item: T) => {
        return (
          <CheckBox
            className={'hospital_filter_checkbox'}
            key={`checkbox_${item.value}`}
            onClick={() => onCheckboxClick(item)}
            isChecked={isChecked(item)}
            size={16}
            isRound={true}>
            <span className={'checkboxLabel'}>{item.label}</span>
          </CheckBox>
        );
      })}
    </Container>
  );
};

export default FilterCheckboxContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toRem(8)};
  padding: ${toRem(16)};

  .hospital_filter_checkbox {
    padding: ${toRem(10)} 0;
  }

  .checkboxLabel {
    margin-left: ${toRem(8)};
    font-weight: 400;
    font-size: ${toRem(15)};
    line-height: ${toRem(19)};
  }
`;
