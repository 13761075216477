import { useCallback, useState } from 'react';
import { useModal } from '@/hooks/useModal';
import webStorage from '@/utils/webStorage';

const useFilterResetModal = () => {
  const filterResetModalProps = useModal();
  const [isNotShowChecked, setIsNotShowChecked] = useState(false);

  const isFilterModalExpired = useCallback((): boolean => {
    const expiredDate = webStorage.getFilterResetModalNotShowCheckTime();
    if (expiredDate) {
      const nowTime = new Date().getTime();
      return Number(expiredDate) - nowTime <= 0;
    }
    return true;
  }, []);

  const handleNotShowCheck = useCallback(() => {
    setIsNotShowChecked(!isNotShowChecked);
  }, [isNotShowChecked]);

  const handleCancelNotShowCheck = useCallback(() => {
    setIsNotShowChecked(false);
  }, []);

  const handleApplyResetModalNotShowToday = useCallback(() => {
    if (isNotShowChecked) {
      const expiredDate = new Date().getTime() + 1000 * 60 * 60 * 24;
      webStorage.setFilterResetModalNotShowCheckTime(expiredDate.toString());
    }
    setIsNotShowChecked(false);
  }, [isNotShowChecked]);

  return {
    filterResetModalProps,
    isNotShowChecked,
    handleNotShowCheck,
    handleCancelNotShowCheck,
    handleApplyResetModalNotShowToday,
    isFilterModalExpired,
  };
};

export default useFilterResetModal;
