import styled from '@emotion/styled';
import React from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import { Body3 } from '@/components/typeface/Body3';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface FilterListButtonProps {
  isSelected: boolean;
  onClick: () => void;
  text: string;
  className?: string;
}

const FilterListButton = ({ isSelected, onClick, text, className }: FilterListButtonProps) => {
  return (
    <Container isSelected={isSelected} onClick={onClick} className={className}>
      <StyledBody3 fontWeight={isSelected ? 700 : 400}>{text}</StyledBody3>
      <FlatIcon iconType={'icDown'} size={10} color={isSelected ? GLOBAL_COLOR.GRAY_700 : GLOBAL_COLOR.GRAY_400} />
    </Container>
  );
};

export default FilterListButton;

const Container = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: 1px solid ${(props) => (props.isSelected ? GLOBAL_COLOR.GRAY_700 : GLOBAL_COLOR.GRAY_200)};
  border-radius: ${toRem(50)};
  padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(14)};
  margin-right: ${toRem(8)};
`;

const StyledBody3 = styled(Body3)`
  margin-right: ${toRem(4)};
`;
