import styled from '@emotion/styled';
import React from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import FilterListButton from '@/components/filter/FilterListButton';
import { FilterHeader } from '@/constants/filter';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface HospitalFilterProps {
  filterList: FilterHeader[];
  isSelected: (i: string) => boolean;
  onFilterOpenClick: (i: FilterHeader) => void;
  onTextChange: (i: FilterHeader) => string;
  onFilterResetAll: () => void;
  isFilterExist: boolean;
  className?: string;
}

const HospitalFilter = ({
  filterList,
  onFilterOpenClick,
  isSelected,
  onTextChange,
  onFilterResetAll,
  isFilterExist,
  className,
}: HospitalFilterProps) => {
  return (
    <Container className={className}>
      <HorizontalScroll>
        {isFilterExist && (
          <ResetContainer onClick={onFilterResetAll}>
            <FlatIcon iconType={'icReset'} size={16} color={GLOBAL_COLOR.GRAY_700} />
            <span>초기화</span>
          </ResetContainer>
        )}
        {filterList.map((filter) => (
          <FilterListButton
            key={`filterList_${filter.value}`}
            isSelected={isSelected(filter.value)}
            onClick={() => onFilterOpenClick(filter)}
            text={onTextChange(filter)}
          />
        ))}
      </HorizontalScroll>
    </Container>
  );
};

export default HospitalFilter;

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: scroll;
  padding: ${toRem(12)} 0;
  -ms-overflow-style: none; /* IE and Edge */

  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const HorizontalScroll = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 0 ${toRem(16)};
`;

const ResetContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  border: 1px solid ${GLOBAL_COLOR.GRAY_200};
  border-radius: ${toRem(50)};
  padding: ${toRem(6)} ${toRem(12)} ${toRem(6)} ${toRem(14)};
  margin-right: ${toRem(8)};

  span {
    font-size: ${toRem(14)};
    line-height: ${toRem(18)};
    font-weight: 500;
    color: ${GLOBAL_COLOR.GRAY_700};
    margin-left: ${toRem(4)};
  }
`;
