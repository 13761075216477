import styled from '@emotion/styled';
import React from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import SingleButton from '@/components/common/buttons/SingleButton';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface FilterApplyButtonProps {
  handleFilterApply: () => void;
  applyText: React.ReactNode;
  handleFilterReset?: () => void;
  resetText?: React.ReactNode;
  isFilterExist?: boolean;
  disabled?: boolean;
}

const FilterApplyButton = ({
  handleFilterApply,
  applyText,
  handleFilterReset,
  resetText,
  isFilterExist,
  disabled,
}: FilterApplyButtonProps) => {
  return (
    <Container>
      {handleFilterReset && (
        <ResetFilterButton onClick={handleFilterReset}>
          <FlatIcon
            iconType={'icResetBold'}
            size={16}
            color={isFilterExist ? GLOBAL_COLOR.GRAY_500 : GLOBAL_COLOR.GRAY_200}
          />
          <ResetText color={isFilterExist ? GLOBAL_COLOR.GRAY_500 : GLOBAL_COLOR.GRAY_200}>{resetText}</ResetText>
        </ResetFilterButton>
      )}
      <SingleButton disabled={disabled} buttonType={'primary'} onClick={handleFilterApply} width={220}>
        <ApplyText>{applyText}</ApplyText>
      </SingleButton>
    </Container>
  );
};

export default FilterApplyButton;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: ${toRem(16)};
`;

const ResetFilterButton = styled.div`
  display: flex;
  width: 45%;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${toRem(12)};
`;

const ResetText = styled.span`
  color: ${(props) => props.color};
  font-size: ${toRem(14)};
  font-weight: 500;
  margin-left: ${toRem(6)};
`;

const ApplyText = styled.div`
  color: ${GLOBAL_COLOR.WHITE};
  font-weight: 700;
  font-size: ${toRem(16)};
`;
