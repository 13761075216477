import styled from '@emotion/styled';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HospitalFilterCountParams, HospitalFilterList, HospitalListParams } from '@/api/hospital';
import FlatIcon from '@/components/common/FlatIcon';
import FilterApplyButton from '@/components/filter/FilterApplyButton';
import FilterCheckboxContent from '@/components/filter/FilterCheckboxContent';
import FilterTypeHeader from '@/components/filter/FilterTypeHeader';
import BottomSheetModal from '@/components/modal/BottomSheetModal';
import { Filter, FILTER_LIST, FILTER_TYPE_KEY, FilterHeader, TIME_FILTER_KEY } from '@/constants/filter';
import { useHospitalFilter } from '@/hooks/useHospitalFilter';
import { ModalProps } from '@/hooks/useModal';
import { useGetHospitalFilterCount } from '@/queries/query/useHospitalListQuery';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

interface HospitalFilterModalProps {
  filterList: Filter[];
  filterItemList?: HospitalFilterList;
  filterModalProps: ModalProps;
  handleFilterApply: (i: Filter[]) => void;
  selectedFilterType: FilterHeader;
  filterType: string;
  listParams: HospitalListParams;
  listEmptyComponent: React.ReactNode;
  isSearchNationWide?: boolean;
}

const HospitalFilterModal = ({
  filterList,
  filterItemList,
  filterModalProps,
  handleFilterApply,
  selectedFilterType,
  filterType,
  listParams,
  listEmptyComponent,
  isSearchNationWide,
}: HospitalFilterModalProps) => {
  const { handleTimeFilterParams, handleServiceFilterParams } = useHospitalFilter(filterType);
  const [tempFilterArr, setTempFilterArr] = useState<Filter[]>(filterList);
  const [selectedFilter, setSelectedFilter] = useState(selectedFilterType);

  const tempServiceFilterParams = useMemo(() => {
    return handleServiceFilterParams(tempFilterArr);
  }, [handleServiceFilterParams, tempFilterArr]);

  const tempTimeFilterParams = useMemo(() => {
    const tempTimeFilter = {
      [TIME_FILTER_KEY.OVERNIGHT]: false,
      [TIME_FILTER_KEY.WEEKEND]: false,
      [TIME_FILTER_KEY.ALLDAY]: false,
    };
    Object.assign(
      tempTimeFilter,
      handleTimeFilterParams(tempFilterArr) && { ...handleTimeFilterParams(tempFilterArr) },
    );
    return tempTimeFilter;
  }, [handleTimeFilterParams, tempFilterArr]);

  const filterCountParams = {
    ...listParams,
    ...tempTimeFilterParams,
    ...(tempServiceFilterParams ? { tagIds: tempServiceFilterParams } : { tagIds: [] }),
  };

  const { data: filterCount } = useGetHospitalFilterCount(filterCountParams as HospitalFilterCountParams);

  const applyText =
    filterCount?.hospitalCount === undefined ? (
      <LoadingSpinner />
    ) : (
      <span>{filterCount?.hospitalCount ?? 0}개 병원보기</span>
    );

  useEffect(() => {
    if (filterModalProps.isShowing) {
      setTempFilterArr([...filterList]);
      setSelectedFilter(selectedFilterType);
    }
  }, [filterList, filterModalProps.isShowing, selectedFilterType]);

  const handleFilterHeaderCheck = (type: string) => {
    return tempFilterArr?.some((filter: Filter) => filter.filterType === type);
  };

  const [filterTypeList, setFilterTypeList] = useState<Filter[]>([]);

  const handleFilterList = useCallback(() => {
    switch (selectedFilter.value) {
      case FILTER_TYPE_KEY.TIME:
        setFilterTypeList(filterItemList?.timeFilter || []);
        break;
      case FILTER_TYPE_KEY.SERVICE:
        setFilterTypeList(filterItemList?.serviceFilter || []);

        break;
      case FILTER_TYPE_KEY.EXPERTISE:
        setFilterTypeList(filterItemList?.expertiseFilter || []);
        break;
      default:
        setFilterTypeList(filterItemList?.timeFilter || []);
        break;
    }
  }, [
    filterItemList?.expertiseFilter,
    filterItemList?.serviceFilter,
    filterItemList?.timeFilter,
    selectedFilter.value,
  ]);

  useEffect(() => {
    handleFilterList();
  }, [handleFilterList]);

  const handleFilterItemSelect = (item: Filter) => {
    const checked = handleFilterChecked(item);
    if (checked) {
      const newArr = [...tempFilterArr];
      const index = tempFilterArr.findIndex((filter) => filter.value === item.value);
      newArr.splice(index, 1);
      setTempFilterArr(newArr);
    } else {
      const newArr = tempFilterArr.concat({ ...item, filterType: selectedFilter.value });
      setTempFilterArr(newArr);
    }
  };

  const handleFilterChecked = (item: Filter): boolean => {
    return tempFilterArr?.some((filter: Filter) => filter.value === item.value);
  };

  const handleFilterReset = () => {
    const newArr = tempFilterArr.filter((item) => item.filterType !== selectedFilter.value);
    setTempFilterArr(newArr);
  };

  return (
    <BottomSheetModal
      height={60}
      isSetScrollTop={true}
      header={
        <FilterTypeHeader
          filterTypeList={FILTER_LIST}
          handleSelect={setSelectedFilter}
          selectedFilter={selectedFilter}
          handleCheckExist={handleFilterHeaderCheck}
        />
      }
      content={
        <FilterContent isExist={filterTypeList.length > 0}>
          {!isSearchNationWide && filterTypeList.length > 0 && (
            <FilterInfo>
              <FlatIcon iconType={'icTip'} size={16} color={GLOBAL_COLOR.GRAY_400} />
              <span>설정된 지역에서 적용 가능한 필터만 보여요</span>
            </FilterInfo>
          )}

          {filterTypeList.length > 0 ? (
            <FilterCheckboxContent
              itemList={filterTypeList}
              onCheckboxClick={handleFilterItemSelect}
              isChecked={handleFilterChecked}
            />
          ) : (
            listEmptyComponent
          )}
        </FilterContent>
      }
      bottom={
        <FilterApplyButton
          handleFilterApply={() => filterCount?.hospitalCount && handleFilterApply(tempFilterArr)}
          applyText={applyText}
          resetText={<span>{selectedFilter.label} 초기화</span>}
          handleFilterReset={handleFilterReset}
          isFilterExist={handleFilterHeaderCheck(selectedFilter.value)}
          disabled={filterCount?.hospitalCount === 0}
        />
      }
      modalProps={filterModalProps}
    />
  );
};

export default HospitalFilterModal;

const FilterInfo = styled.div`
  display: flex;
  align-items: center;
  padding: ${toRem(8)} ${toRem(16)} 0;

  color: ${GLOBAL_COLOR.GRAY_400};
  font-size: ${toRem(14)};
  font-weight: 500;
  line-height: ${toRem(20)};

  span {
    margin-left: ${toRem(8)};
  }
`;

const FilterContent = styled.div<{ isExist: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${({ isExist }) => (isExist ? 0 : 'auto')};
`;

const LoadingSpinner = styled.div`
  border: ${toRem(2)} solid #f3f3f3;
  border-radius: 50%;
  border-top: ${toRem(2)} solid ${GLOBAL_COLOR.BLUE_600};
  width: ${toRem(16)};
  height: ${toRem(16)};
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
